<template>
  <div
    class="bigContainer"
    v-if="hasContent"
  >
    <!-- <UspsContentBlock /> -->
    <component
      v-for="(contentblock, idx) in contentBlocks"
      :key="idx"
      :is="getComponentByName(contentblock._block._name)"
      :content="contentblock"
      class="ContentBlock"
      :class="contentblock._block._name"
    />
  </div>
</template>
<script setup lang="ts">


interface Props {
  data: {
    data: {
      _id: number;
    };
    content: {
      content: [
        {
          _block: {
            _id: Number;
            _name: String;
          };
        }
      ];
    };
    metadata: {
      afbeelding: number;
      canonical: string;
      indexeren: string;
      omschrijving: string;
      titel: string;
    };
  };
}
// TODO: moet metaData op contentblocks container niveau of op een hoger niveau?
const props = defineProps<Props>();

const hasContent = computed(() => !!props.data?.content);
const contentBlocks = computed(() =>
  props.data.content.content.filter((block) => {
    if (!block.publicatiedatumVan && !block.publicatiedatumTot) {
      return true;
    }
    if (block.publicatiedatumTot && new Date(block.publicatiedatumTot) < new Date()) {
      return false;
    }
    if (block.publicatiedatumVan && new Date(block.publicatiedatumVan) > new Date()) {
      return false;
    }
    return true;
  })
);


function getComponentByName(name: string) {
  switch(name) {
    case 'Header':
      return defineAsyncComponent(() => import('../components/contentblokken/HeaderContentBlock.vue'));
        case 'Tekst 1 koloms':
      return defineAsyncComponent(() => import('../components/contentblokken/TekstEenKolomsContentBlock.vue'));
        case 'Tekst 2 koloms':
      return defineAsyncComponent(() => import('../components/contentblokken/TekstTweeKolomsContentBlock.vue'));
        case 'Titel':
      return defineAsyncComponent(() => import('../components/contentblokken/TitelContentBlock.vue'));
        case 'Afbeelding':
      return defineAsyncComponent(() => import('../components/contentblokken/AfbeeldingContentBlock.vue'));
        case 'Tekst en afbeelding':
      return defineAsyncComponent(() => import('../components/contentblokken/TekstEnAfbeeldingContentBlock.vue'));
        case 'Veelgestelde vragen':
      return defineAsyncComponent(() => import('../components/contentblokken/VeelgesteldeVragenContentBlock.vue'));
        case 'Documenten':
      return defineAsyncComponent(() => import('../components/contentblokken/DocumentenContentBlock.vue'));
        case 'Video':
      return defineAsyncComponent(() => import('../components/contentblokken/VideoContentBlock.vue'));
        case 'Galerij':
      return defineAsyncComponent(() => import('../components/contentblokken/GallerijContentBlock.vue'));
        case 'Carrousel':
      return defineAsyncComponent(() => import('../components/contentblokken/CarouselContentBlock.vue'));
        case 'Embed':
      return defineAsyncComponent(() => import('../components/contentblokken/EmbedContentBlock.vue'));
        case 'Accommodaties':
      return defineAsyncComponent(() => import('../components/contentblokken/AccommodatieContentBlock.vue'));
        case "Gerelateerde pagina's ":
      return defineAsyncComponent(() => import('../components/contentblokken/GerelateerdePaginas.vue'));
        case 'Afbeelding - Volledige breedte':
      return defineAsyncComponent(() => import('../components/contentblokken/AfbeeldingBannerContentBlock.vue'));
        case 'Recent bekeken':
      return defineAsyncComponent(() => import('../components/contentblokken/RecentBekekenContentBlock.vue'));
        case 'Embed Script':
      return defineAsyncComponent(() => import('../components/contentblokken/EmbedScriptContentBlock.vue'));
        case 'Quote':
      return defineAsyncComponent(() => import('../components/contentblokken/QuoteContentBlock.vue'));
        case 'Zoek en boek':
      return defineAsyncComponent(() => import('../components/contentblokken/ZoekEnBoekContentBlock.vue'));
        case 'Header + Tekst en afbeelding':
      return defineAsyncComponent(() => import('../components/contentblokken/HeaderTextAndImageContentBlock.vue'));
        case 'Usps':
      return defineAsyncComponent(() => import('../components/contentblokken/UspsContentBlock.vue'));
        case 'Quicklinks':
      return defineAsyncComponent(() => import('../components/contentblokken/QuickLinks.vue'));
    default:
      return null;	
  }
}

</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.bigContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ContentBlock {
  padding: 40px 0;
  width: 100%;
  max-width: $grid-size;

  &:first-child {
    border-top: none;
  }

  &.Header,
  &.Carrousel,
  &.AfbeeldingBannerContentBlock,
  &.HeaderTextAndImageContentBlock {
    max-width: 100%;
    border-bottom: none;
    padding: 0 !important;
  }

  &.UspsContentBlock {
    padding: 0;
  }
}

@media (max-width: 1300px) {
  .ContentBlock {
    padding: 42px 24px;
  }
}

@media (max-width: $screen-xs) {
  .ContentBlock {
    padding: 24px;

    &.UspsContentBlock {
      padding: 0 24px;
    }
  }
}
</style>
