import revive_payload_client_4sVQNw7RlN from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_LcKgStRyi6 from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import quasar_plugin_G9rpXFQbul from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/.nuxt/quasar-plugin.mjs";
import provide_4gUmUdUSZU from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/nuxt-quasar-ui/dist/runtime/provide.mjs";
import chunk_reload_client_UciE0i6zes from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import getFilters_pbN6PwYGLo from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/plugins/getFilters.ts";
import getPages_VN8hzSKXFr from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/plugins/getPages.ts";
import getUser_mRezc5YeZi from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/plugins/getUser.ts";
import i18n_VfGcjrvSkj from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/plugins/i18n.ts";
import sentry_client_shVUlIjFLk from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/plugins/sentry.client.ts";
import setVars_TQEgAVpdmU from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/plugins/setVars.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_client_LcKgStRyi6,
  quasar_plugin_G9rpXFQbul,
  provide_4gUmUdUSZU,
  chunk_reload_client_UciE0i6zes,
  getFilters_pbN6PwYGLo,
  getPages_VN8hzSKXFr,
  getUser_mRezc5YeZi,
  i18n_VfGcjrvSkj,
  sentry_client_shVUlIjFLk,
  setVars_TQEgAVpdmU
]