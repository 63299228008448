import validate from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import routing_45global from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/middleware/routing.global.ts";
import manifest_45route_45rule from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  routing_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "account-middleware": () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/middleware/account-middleware.ts"),
  "admin-route-mddleware": () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/middleware/admin-route-mddleware.ts"),
  "owners-middleware": () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/middleware/owners-middleware.ts"),
  "owners-overview-middleware": () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/middleware/owners-overview-middleware.ts")
}