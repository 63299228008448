<template>
  <footer class="footer row">
    <div class="newsLetter">
      <div class="q-pt-xl q-pb-xl blockContainer">
        <div
          class="textLowPriority q-mb-sm"
          v-t="'landing.newsletter.descr'"
        />

        <p
          class="heading3 col-4"
          v-t="'landing.newsletter.title'"
        />

        <q-btn
          color="primary"
          class="q-mt-md"
          to="/nieuwsbrief"
        >
          <q-icon
            name="icon-chevron-right"
            class="q-mr-sm"
          />
          Inschrijven
        </q-btn>
      </div>
    </div>

    <div class="usefulLinks row blockContainer q-gutter-y-xl">
      <div class="col-12 col-sm-3">
        <div
          class="heading4"
          v-t="'landing.footer.usefulLinks'"
        />
        <div class="q-mt-lg q-gutter-md">
          <NuxtLink
            v-for="link in quickLinks"
            :to="`/${link.content.slug}`"
            class="link textLink light"
          >
            {{ link.content.titel }}
          </NuxtLink>
        </div>
      </div>

      <div class="col-12 col-sm-3">
        <div
          class="heading4"
          v-t="'landing.footer.contact'"
        />
        <div class="q-mt-lg light">
          <div v-t="'landing.footer.companyName'" />
          <div v-t="'landing.footer.address'" />
          <div v-t="'landing.footer.postalCodeCity'" />
        </div>

        <div class="q-mt-sm light">
          <div v-t="'landing.footer.telephone'" />
          <div>customerservice@joyforitaly.com</div>
        </div>

        <!-- <div class="q-mt-sm light">
          <div v-t="'landing.footer.info'" />
        </div> -->

        <div class="q-mt-sm column q-gutter-sm">
          <strong> Onderdeel van</strong>
          <img
            loading="lazy"
            class="joyForItalyLogo"
            alt="Joy for italy logo"
            src="~/assets/joy-logo-zwart.svg"
          />
        </div>
      </div>

      <div class="col-12 col-sm-3">
        <div
          class="heading4"
          v-t="'landing.footer.lastNews'"
        />

        <div class="q-mt-lg">
          <a
            href="https://www.facebook.com/EccoItaliaNL"
            target="_blank"
          >
            <q-icon
              name="icon-facebook"
              class="socialButton"
              size="sm"
            />
          </a>

          <a
            href="https://twitter.com/eccoitalia"
            target="_blank"
          >
            <q-icon
              name="icon-twitter"
              class="socialButton"
              size="sm"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UC9Ux71RHm4DL6w02tiGk8Bg"
            target="_blank"
          >
            <q-icon
              name="icon-youtube"
              class="socialButton"
              size="sm"
            />
          </a>
        </div>

        <div
          class="heading4 q-mt-lg"
          v-t="'landing.footer.sgr'"
        />
        <img
          loading="lazy"
          src="~/assets/sgr.svg"
          alt="SRG Garantie"
          class="srgImage"
        />
      </div>

      <div class="col-12 col-sm-3">
        <div
          class="heading4"
          v-t="'landing.footer.about'"
        />
        <div class="q-mt-lg q-gutter-md">
          <NuxtLink
            v-for="link in about"
            :to="`/${link.content.slug}`"
            class="link textLink light"
          >
            {{ link.content.titel }}
          </NuxtLink>
        </div>
      </div>
    </div>

    <div class="companiesContainer q-mt-xl row justify-between">
      <div class="col-9 offset-sm-1 row q-gutter-md">
        <div class="link">Copyright © 2023 Ecco Italia</div>
        <NuxtLink
          v-for="link in footerLinks"
          :to="`/${link.content.slug}`"
          class="link textLink light"
        >
          {{ link.content.titel }}
        </NuxtLink>
      </div>
      <div class="col-sm-2 xs-col-12">
        <a
          href="https://cre8ion.com"
          target="_blank"
        >
          Website by The Cre8ion.Lab
        </a>
      </div>
      <div class="col-sm-1"></div>
      <!-- <span v-for="(company, idx) in companies" :key="idx" v-text="company" /> -->
    </div>
  </footer>
</template>

<script setup lang="ts">
import useElocusStore from '~/store/elocus';

const elocusStore = useElocusStore();

const quickLinks = computed(() => elocusStore.quickLinks);
const about = computed(() => elocusStore.about);
const footerLinks = computed(() => elocusStore.footerLinks);
// eslint-disable-next-line
// const companies = ['Copyright © 2023 Ecco Italia', 'Agriturismo Italia', 'Agriturismo', 'Agriturismo Toscane', 'Agriturismo Sicilië', 'Vakantiehuis Italië'];
</script>

<style lang="scss" scoped>
@import './styles/variables.sass';

.companiesContainer {
  background: $primary;

  span {
    color: $text-regular;
  }

  .navItem {
    padding: 0 15px;
  }
}

.socialButton {
  padding: 8px;
  margin-right: 16px;
  border-radius: 100%;
  border: 2px solid $primary;
  transition: 0.25s;
  cursor: pointer;

  &:hover {
    background: $primary;
  }
}

.joyForItalyLogo {
  width: 100px;
  height: 85px;
}

.srgImage {
  width: 100px;
  height: 88px;
}

.footer {
  width: 100%;

  > div {
    width: 100%;
  }

  span {
    padding: 10px;
  }

  border-bottom: 52px solid $primary-dark;

  .link {
    color: $text-regular;
    display: block;
  }
}

.newsLetter {
  background: $backgrounds-50;
  // color: white;
  width: 100%;
}

.companiesContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
}

@media (max-width: $screen-xs) {
}
</style>
