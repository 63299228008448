import { default as _91level3_934yGOm4UlFuMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/[slug]/[level2]/[level3].vue?macro=true";
import { default as indexjPo1YsSEwrMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/[slug]/[level2]/index.vue?macro=true";
import { default as indexyYFavD0PGgMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/[slug]/index.vue?macro=true";
import { default as _91id_93FAD7D5oV11Meta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/aankomende-reizen/[id].vue?macro=true";
import { default as indexPAmOVZtwLpMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/aankomende-reizen/index.vue?macro=true";
import { default as token_45verlopen2D5XORJeZlMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/aankomende-reizen/token-verlopen.vue?macro=true";
import { default as _91id_93Ja0Zta8su5Meta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/accommodaties/[id].vue?macro=true";
import { default as index4IRJJ1Z8iYMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/accommodaties/index.vue?macro=true";
import { default as account_45bewerkenscQ82SsIXVMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/account-bewerken.vue?macro=true";
import { default as accountd6Mvjhx9r7Meta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/account.vue?macro=true";
import { default as gedaanbWVN4G153OMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boeken/gedaan.vue?macro=true";
import { default as indexRhWv9svoYwMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boeken/index.vue?macro=true";
import { default as boeking_45aanmaken_45klantE1N3rTy9d6Meta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boeking-aanmaken-klant.vue?macro=true";
import { default as _91bookingId_93nvmidnevjpMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/[bookingId].vue?macro=true";
import { default as _91userId_93PSzY65TnMBMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/admin/[bookingId]/[userId].vue?macro=true";
import { default as indexifRTn0LNl4Meta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/admin/index.vue?macro=true";
import { default as indexQf1PDYzGRQMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/index.vue?macro=true";
import { default as contactFMzym719OzMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/contact.vue?macro=true";
import { default as _91id_93f8moiaSBfwMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/[id].vue?macro=true";
import { default as _91id_931WOfqYngdYMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/admin/[id].vue?macro=true";
import { default as indexXPY2MLF8Q4Meta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/admin/index.vue?macro=true";
import { default as indexaZyv1hYNneMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/index.vue?macro=true";
import { default as erroreP37r4bg86Meta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/error.vue?macro=true";
import { default as favorieten9AzR1092e7Meta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/favorieten.vue?macro=true";
import { default as indexBbsXwZKx7xMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/index.vue?macro=true";
import { default as _91inquiryId_93bMFW6VEoUnMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/inkomende-reisaanvragen/[inquiryId].vue?macro=true";
import { default as indexr55U0CWdL0Meta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/inkomende-reisaanvragen/index.vue?macro=true";
import { default as nieuwsbrief5Jy28L435qMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/nieuwsbrief.vue?macro=true";
import { default as answer_45availabilityiEd2ZJajo0Meta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/owner/answer-availability.vue?macro=true";
import { default as reis_45aanvragen_45klantHKAcDygGCIMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/reis-aanvragen-klant.vue?macro=true";
import { default as reis_45aanvragenXeZVVOfS3QMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/reis-aanvragen.vue?macro=true";
import { default as team_45beheren4pGGZaZeCBMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/team-beheren.vue?macro=true";
import { default as winkelwagenq4jdZHEedHMeta } from "D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/winkelwagen.vue?macro=true";
export default [
  {
    name: _91level3_934yGOm4UlFuMeta?.name ?? "slug-level2-level3",
    path: _91level3_934yGOm4UlFuMeta?.path ?? "/:slug()/:level2()/:level3()",
    meta: _91level3_934yGOm4UlFuMeta || {},
    alias: _91level3_934yGOm4UlFuMeta?.alias || [],
    redirect: _91level3_934yGOm4UlFuMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/[slug]/[level2]/[level3].vue").then(m => m.default || m)
  },
  {
    name: indexjPo1YsSEwrMeta?.name ?? "slug-level2",
    path: indexjPo1YsSEwrMeta?.path ?? "/:slug()/:level2()",
    meta: indexjPo1YsSEwrMeta || {},
    alias: indexjPo1YsSEwrMeta?.alias || [],
    redirect: indexjPo1YsSEwrMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/[slug]/[level2]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyYFavD0PGgMeta?.name ?? "slug",
    path: indexyYFavD0PGgMeta?.path ?? "/:slug()",
    meta: indexyYFavD0PGgMeta || {},
    alias: indexyYFavD0PGgMeta?.alias || [],
    redirect: indexyYFavD0PGgMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93FAD7D5oV11Meta?.name ?? "aankomende-reizen-id",
    path: _91id_93FAD7D5oV11Meta?.path ?? "/aankomende-reizen/:id()",
    meta: _91id_93FAD7D5oV11Meta || {},
    alias: _91id_93FAD7D5oV11Meta?.alias || [],
    redirect: _91id_93FAD7D5oV11Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/aankomende-reizen/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPAmOVZtwLpMeta?.name ?? "aankomende-reizen",
    path: indexPAmOVZtwLpMeta?.path ?? "/aankomende-reizen",
    meta: indexPAmOVZtwLpMeta || {},
    alias: indexPAmOVZtwLpMeta?.alias || [],
    redirect: indexPAmOVZtwLpMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/aankomende-reizen/index.vue").then(m => m.default || m)
  },
  {
    name: token_45verlopen2D5XORJeZlMeta?.name ?? "aankomende-reizen-token-verlopen",
    path: token_45verlopen2D5XORJeZlMeta?.path ?? "/aankomende-reizen/token-verlopen",
    meta: token_45verlopen2D5XORJeZlMeta || {},
    alias: token_45verlopen2D5XORJeZlMeta?.alias || [],
    redirect: token_45verlopen2D5XORJeZlMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/aankomende-reizen/token-verlopen.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Ja0Zta8su5Meta?.name ?? "accommodaties-id",
    path: _91id_93Ja0Zta8su5Meta?.path ?? "/accommodaties/:id()",
    meta: _91id_93Ja0Zta8su5Meta || {},
    alias: _91id_93Ja0Zta8su5Meta?.alias || [],
    redirect: _91id_93Ja0Zta8su5Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/accommodaties/[id].vue").then(m => m.default || m)
  },
  {
    name: index4IRJJ1Z8iYMeta?.name ?? "accommodaties",
    path: index4IRJJ1Z8iYMeta?.path ?? "/accommodaties",
    meta: index4IRJJ1Z8iYMeta || {},
    alias: index4IRJJ1Z8iYMeta?.alias || [],
    redirect: index4IRJJ1Z8iYMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/accommodaties/index.vue").then(m => m.default || m)
  },
  {
    name: account_45bewerkenscQ82SsIXVMeta?.name ?? "account-bewerken",
    path: account_45bewerkenscQ82SsIXVMeta?.path ?? "/account-bewerken",
    meta: account_45bewerkenscQ82SsIXVMeta || {},
    alias: account_45bewerkenscQ82SsIXVMeta?.alias || [],
    redirect: account_45bewerkenscQ82SsIXVMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/account-bewerken.vue").then(m => m.default || m)
  },
  {
    name: accountd6Mvjhx9r7Meta?.name ?? "account",
    path: accountd6Mvjhx9r7Meta?.path ?? "/account",
    meta: accountd6Mvjhx9r7Meta || {},
    alias: accountd6Mvjhx9r7Meta?.alias || [],
    redirect: accountd6Mvjhx9r7Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/account.vue").then(m => m.default || m)
  },
  {
    name: gedaanbWVN4G153OMeta?.name ?? "boeken-gedaan",
    path: gedaanbWVN4G153OMeta?.path ?? "/boeken/gedaan",
    meta: gedaanbWVN4G153OMeta || {},
    alias: gedaanbWVN4G153OMeta?.alias || [],
    redirect: gedaanbWVN4G153OMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boeken/gedaan.vue").then(m => m.default || m)
  },
  {
    name: indexRhWv9svoYwMeta?.name ?? "boeken",
    path: indexRhWv9svoYwMeta?.path ?? "/boeken",
    meta: indexRhWv9svoYwMeta || {},
    alias: indexRhWv9svoYwMeta?.alias || [],
    redirect: indexRhWv9svoYwMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boeken/index.vue").then(m => m.default || m)
  },
  {
    name: boeking_45aanmaken_45klantE1N3rTy9d6Meta?.name ?? "boeking-aanmaken-klant",
    path: boeking_45aanmaken_45klantE1N3rTy9d6Meta?.path ?? "/boeking-aanmaken-klant",
    meta: boeking_45aanmaken_45klantE1N3rTy9d6Meta || {},
    alias: boeking_45aanmaken_45klantE1N3rTy9d6Meta?.alias || [],
    redirect: boeking_45aanmaken_45klantE1N3rTy9d6Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boeking-aanmaken-klant.vue").then(m => m.default || m)
  },
  {
    name: _91bookingId_93nvmidnevjpMeta?.name ?? "boekingen-bookingId",
    path: _91bookingId_93nvmidnevjpMeta?.path ?? "/boekingen/:bookingId()",
    meta: _91bookingId_93nvmidnevjpMeta || {},
    alias: _91bookingId_93nvmidnevjpMeta?.alias || [],
    redirect: _91bookingId_93nvmidnevjpMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/[bookingId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_93PSzY65TnMBMeta?.name ?? "boekingen-admin-bookingId-userId",
    path: _91userId_93PSzY65TnMBMeta?.path ?? "/boekingen/admin/:bookingId()/:userId()",
    meta: _91userId_93PSzY65TnMBMeta || {},
    alias: _91userId_93PSzY65TnMBMeta?.alias || [],
    redirect: _91userId_93PSzY65TnMBMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/admin/[bookingId]/[userId].vue").then(m => m.default || m)
  },
  {
    name: indexifRTn0LNl4Meta?.name ?? "boekingen-admin",
    path: indexifRTn0LNl4Meta?.path ?? "/boekingen/admin",
    meta: indexifRTn0LNl4Meta || {},
    alias: indexifRTn0LNl4Meta?.alias || [],
    redirect: indexifRTn0LNl4Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexQf1PDYzGRQMeta?.name ?? "boekingen",
    path: indexQf1PDYzGRQMeta?.path ?? "/boekingen",
    meta: indexQf1PDYzGRQMeta || {},
    alias: indexQf1PDYzGRQMeta?.alias || [],
    redirect: indexQf1PDYzGRQMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/boekingen/index.vue").then(m => m.default || m)
  },
  {
    name: contactFMzym719OzMeta?.name ?? "contact",
    path: contactFMzym719OzMeta?.path ?? "/contact",
    meta: contactFMzym719OzMeta || {},
    alias: contactFMzym719OzMeta?.alias || [],
    redirect: contactFMzym719OzMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91id_93f8moiaSBfwMeta?.name ?? "eigenaren-id",
    path: _91id_93f8moiaSBfwMeta?.path ?? "/eigenaren/:id()",
    meta: _91id_93f8moiaSBfwMeta || {},
    alias: _91id_93f8moiaSBfwMeta?.alias || [],
    redirect: _91id_93f8moiaSBfwMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_931WOfqYngdYMeta?.name ?? "eigenaren-admin-id",
    path: _91id_931WOfqYngdYMeta?.path ?? "/eigenaren/admin/:id()",
    meta: _91id_931WOfqYngdYMeta || {},
    alias: _91id_931WOfqYngdYMeta?.alias || [],
    redirect: _91id_931WOfqYngdYMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/admin/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXPY2MLF8Q4Meta?.name ?? "eigenaren-admin",
    path: indexXPY2MLF8Q4Meta?.path ?? "/eigenaren/admin",
    meta: indexXPY2MLF8Q4Meta || {},
    alias: indexXPY2MLF8Q4Meta?.alias || [],
    redirect: indexXPY2MLF8Q4Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexaZyv1hYNneMeta?.name ?? "eigenaren",
    path: indexaZyv1hYNneMeta?.path ?? "/eigenaren",
    meta: indexaZyv1hYNneMeta || {},
    alias: indexaZyv1hYNneMeta?.alias || [],
    redirect: indexaZyv1hYNneMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/eigenaren/index.vue").then(m => m.default || m)
  },
  {
    name: erroreP37r4bg86Meta?.name ?? "error",
    path: erroreP37r4bg86Meta?.path ?? "/error",
    meta: erroreP37r4bg86Meta || {},
    alias: erroreP37r4bg86Meta?.alias || [],
    redirect: erroreP37r4bg86Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/error.vue").then(m => m.default || m)
  },
  {
    name: favorieten9AzR1092e7Meta?.name ?? "favorieten",
    path: favorieten9AzR1092e7Meta?.path ?? "/favorieten",
    meta: favorieten9AzR1092e7Meta || {},
    alias: favorieten9AzR1092e7Meta?.alias || [],
    redirect: favorieten9AzR1092e7Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/favorieten.vue").then(m => m.default || m)
  },
  {
    name: indexBbsXwZKx7xMeta?.name ?? "index",
    path: indexBbsXwZKx7xMeta?.path ?? "/",
    meta: indexBbsXwZKx7xMeta || {},
    alias: indexBbsXwZKx7xMeta?.alias || [],
    redirect: indexBbsXwZKx7xMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91inquiryId_93bMFW6VEoUnMeta?.name ?? "inkomende-reisaanvragen-inquiryId",
    path: _91inquiryId_93bMFW6VEoUnMeta?.path ?? "/inkomende-reisaanvragen/:inquiryId()",
    meta: _91inquiryId_93bMFW6VEoUnMeta || {},
    alias: _91inquiryId_93bMFW6VEoUnMeta?.alias || [],
    redirect: _91inquiryId_93bMFW6VEoUnMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/inkomende-reisaanvragen/[inquiryId].vue").then(m => m.default || m)
  },
  {
    name: indexr55U0CWdL0Meta?.name ?? "inkomende-reisaanvragen",
    path: indexr55U0CWdL0Meta?.path ?? "/inkomende-reisaanvragen",
    meta: indexr55U0CWdL0Meta || {},
    alias: indexr55U0CWdL0Meta?.alias || [],
    redirect: indexr55U0CWdL0Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/inkomende-reisaanvragen/index.vue").then(m => m.default || m)
  },
  {
    name: nieuwsbrief5Jy28L435qMeta?.name ?? "nieuwsbrief",
    path: nieuwsbrief5Jy28L435qMeta?.path ?? "/nieuwsbrief",
    meta: nieuwsbrief5Jy28L435qMeta || {},
    alias: nieuwsbrief5Jy28L435qMeta?.alias || [],
    redirect: nieuwsbrief5Jy28L435qMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/nieuwsbrief.vue").then(m => m.default || m)
  },
  {
    name: answer_45availabilityiEd2ZJajo0Meta?.name ?? "owner-answer-availability",
    path: answer_45availabilityiEd2ZJajo0Meta?.path ?? "/owner/answer-availability",
    meta: answer_45availabilityiEd2ZJajo0Meta || {},
    alias: answer_45availabilityiEd2ZJajo0Meta?.alias || [],
    redirect: answer_45availabilityiEd2ZJajo0Meta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/owner/answer-availability.vue").then(m => m.default || m)
  },
  {
    name: reis_45aanvragen_45klantHKAcDygGCIMeta?.name ?? "reis-aanvragen-klant",
    path: reis_45aanvragen_45klantHKAcDygGCIMeta?.path ?? "/reis-aanvragen-klant",
    meta: reis_45aanvragen_45klantHKAcDygGCIMeta || {},
    alias: reis_45aanvragen_45klantHKAcDygGCIMeta?.alias || [],
    redirect: reis_45aanvragen_45klantHKAcDygGCIMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/reis-aanvragen-klant.vue").then(m => m.default || m)
  },
  {
    name: reis_45aanvragenXeZVVOfS3QMeta?.name ?? "reis-aanvragen",
    path: reis_45aanvragenXeZVVOfS3QMeta?.path ?? "/reis-aanvragen",
    meta: reis_45aanvragenXeZVVOfS3QMeta || {},
    alias: reis_45aanvragenXeZVVOfS3QMeta?.alias || [],
    redirect: reis_45aanvragenXeZVVOfS3QMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/reis-aanvragen.vue").then(m => m.default || m)
  },
  {
    name: team_45beheren4pGGZaZeCBMeta?.name ?? "team-beheren",
    path: team_45beheren4pGGZaZeCBMeta?.path ?? "/team-beheren",
    meta: team_45beheren4pGGZaZeCBMeta || {},
    alias: team_45beheren4pGGZaZeCBMeta?.alias || [],
    redirect: team_45beheren4pGGZaZeCBMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/team-beheren.vue").then(m => m.default || m)
  },
  {
    name: winkelwagenq4jdZHEedHMeta?.name ?? "winkelwagen",
    path: winkelwagenq4jdZHEedHMeta?.path ?? "/winkelwagen",
    meta: winkelwagenq4jdZHEedHMeta || {},
    alias: winkelwagenq4jdZHEedHMeta?.alias || [],
    redirect: winkelwagenq4jdZHEedHMeta?.redirect,
    component: () => import("D:/GitLab-Runner/build/aDia6wPnr/0/SmookItalia/smookitalia-nuxt-app/pages/winkelwagen.vue").then(m => m.default || m)
  }
]