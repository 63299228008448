<template>
  <DialogComponent title="Filters">
    <div class="container">
      <div
        class="block"
        v-if="filters[LABELS].length || filters[DISCOUNTS].length"
      >
        <div
          class="textRegular"
          v-t="'landing.filters.labels'"
        />
        <div class="row q-mt-md">
          <ChipFilters
            :options="filters[LABELS]"
            v-model="filterData[LABELS]"
          />

          <ChipFilters
            :options="filters[DISCOUNTS]"
            v-model="filterData[DISCOUNTS]"
          />
        </div>
      </div>

      <div
        class="block"
        v-if="filters[ACCOMODATION_TYPES].length"
      >
        <div
          class="textRegular"
          v-t="'landing.filters.accomodationType'"
        />
        <ChipFilters
          class="q-mt-md"
          :options="filters[ACCOMODATION_TYPES]"
          v-model="filterData[ACCOMODATION_TYPES]"
          single-select
        />
        <div v-if="filterData[ACCOMODATION_TYPES]?.length && showSubFilters.length">
          <div class="q-my-sm">{{ filterData[ACCOMODATION_TYPES][0]?.label }} filters</div>
          <ChipFilters
            :key="filterData[ACCOMODATION_TYPES][0].value"
            :options="showSubFilters"
            v-model="filterData[ACCOMODATION_SUB_FILTERS]"
          />
        </div>
      </div>

      <div
        class="block"
        v-if="filters[QUALITY_LABELS].length"
      >
        <div
          class="textRegular"
          v-t="'landing.filters.qualityLabel'"
        />
        <ChipFilters
          class="q-mt-md"
          :options="filters[QUALITY_LABELS]"
          v-model="filterData[QUALITY_LABELS]"
        />
      </div>

      <div
        class="block"
        v-if="filters[HOLIDAY_TYPES].length"
      >
        <div
          class="textRegular"
          v-t="'landing.filters.typeHoliday'"
        />
        <ChipFilters
          class="q-mt-md"
          :options="filters[HOLIDAY_TYPES]"
          v-model="filterData[HOLIDAY_TYPES]"
        />
      </div>

      <div class="block">
        <div
          class="textRegular"
          v-t="'landing.filters.price'"
        />

        <PriceRangeSlider
          v-model="filterData.price"
          :min-price="filterData.minPrice"
          :max-price="filterData.maxPrice"
        />
      </div>

      <div class="block">
        <div
          class="textRegular"
          v-t="'landing.filters.facilities'"
        />

        <div class="optionContainer">
          <q-option-group
            :options="facilitiesToDisplay"
            v-model="filterData.features"
            type="checkbox"
            color="primary-dark"
            inline
            dense
          />
        </div>

        <div
          class="textLink q-mt-md pointer"
          @click="amountFacilitiesToDisplay = filters[FEATURES].length - 1"
          v-if="amountFacilitiesToDisplay < filters[FEATURES].length - 1"
        >
          Toon alle voorzieningen
        </div>
      </div>

      <div class="bottom q-mt-md q-gutter-md">
        <q-btn
          color="primary"
          @click="setFilters"
        >
          Instellen
        </q-btn>

        <q-btn
          text
          @click="resetFilters"
        >
          Wissen
        </q-btn>
      </div>
    </div>
  </DialogComponent>
</template>

<script setup lang="ts">
import useStore from '~/store/store';
import useAuthStore from '~/store/auth';
import { HOLIDAY_TYPES, ACCOMODATION_TYPES, FEATURES, QUALITY_LABELS, LABELS, DISCOUNTS, ACCOMODATION_SUB_FILTERS, USER_LEVELS } from '~/config';

const props = defineProps({
  activeFilters: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['set-filters']);
const store = useStore();
const authStore = useAuthStore();

const filters = unref(store.filters);
if (authStore.user?.userLevel === USER_LEVELS.Reseller) {
  // Reseller can't book NRF
  filters[LABELS] = filters[LABELS].filter((item) => item.value !== 10162051);
}

const amountFacilitiesToDisplay = ref(9);
const facilitiesToDisplay = computed(() => filters[FEATURES].slice(0, amountFacilitiesToDisplay.value));
// Kind of redundant, but quasar only uses value for v-model on option group
const facilitiesMappedBack = computed(() =>
  filterData[FEATURES].filter((item: Filter) => item).map((id: number) => filters[FEATURES].find((item: Filter) => item.value === id))
);
const filterData = reactive(JSON.parse(JSON.stringify(props.activeFilters)));

filterData[FEATURES] = filterData[FEATURES]?.length ? filterData[FEATURES].map((item: Filter) => item.value) : [];

const showSubFilters = computed(() =>
  filterData[ACCOMODATION_TYPES]?.length ? store.filterAdminOptions(filterData[ACCOMODATION_TYPES][0]?.subFilters) : []
);

const accoTypes = computed(() => filterData[ACCOMODATION_TYPES]);
watch(
  accoTypes,
  () => {
    filterData[ACCOMODATION_SUB_FILTERS] = [];
  },
  { deep: true }
);

function setFilters() {
  const data = {
    ...filterData,
    minPrice: filterData.price?.min || props.activeFilters?.minPrice || 0,
    maxPrice: filterData.price?.max || props.activeFilters?.maxPrice,
    [FEATURES]: facilitiesMappedBack.value,
  };
  delete data.price;
  emit('set-filters', data);
}

function resetFilters() {
  const defaultDataModel = {
    [ACCOMODATION_TYPES]: [],
    [ACCOMODATION_SUB_FILTERS]: [],
    [HOLIDAY_TYPES]: [],
    [FEATURES]: [],
    [QUALITY_LABELS]: [],
    [LABELS]: [],
    [DISCOUNTS]: [],
  };

  Object.keys(filterData).forEach((key) => {
    filterData[key] = defaultDataModel[key];
  });
  emit('set-filters', filterData);
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.container {
  max-width: 760px;

  .block {
    padding: 25px 0;
    border-bottom: 1px solid $sea-blue-300;

    .textRegular {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .optionContainer {
    display: flex;
    flex-wrap: wrap;

    .option {
      width: 33%;
    }
  }
}

:deep(.q-option-group) {
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-top: 10px;
    width: 32%;
  }
}

@media (max-width: $screen-xs) {
  .optionContainer {
    flex-direction: column;
  }

  .container {
    padding-bottom: 80px;
  }

  .bottom {
    position: fixed;
    bottom: 0;
    background: white;
    width: 100%;
    height: 80px;
  }

  :deep(.q-option-group) {
    display: flex;
    flex-direction: column;

    > * {
      width: 100%;
    }
  }
}
</style>
store/store
